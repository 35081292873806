<template>
  <s-drawer
    v-model:visible="show"
    type="full"
    direction="rtl"
    append-to-body
    no-header
    immediately-render
  >
    <div class="j-map-address j-address-box c-google-map-modal">
      <div>
        <s-title-nav
          ref="upperHeader"
          class="c-address-header"
          :title="language.SHEIN_KEY_PWA_16611"
        >
          <template #prepend>
            <s-title-nav-item
              ref="upperTitle"
              is-back-icon
              @click="clickCancel"
            />
          </template>
        </s-title-nav>
        <div class="c-address-content c-map-wrap ru">
          <span
            v-if="mapObj"
            v-enterkey
            class="search-btn"
            tabindex="0"
            role="button"
            @click="showCascader"
          >
            <i class="suiiconfont sui_icon_nav_search_18px"></i>
          </span>
          <div
            id="j-store-map-ru"
            class="map"
          ></div>
        </div>
        <!-- 选择 滑出层-->
        <SLazyMount>
          <s-drawer
            v-model:visible="chooseAddressDrawer"
            :modal="false"
            style="pointer-events: none"
            class="choose-address-page"
          >
            <div
              class="choose-address-wrap"
              style="pointer-events: auto"
            >
              <div
                class="header-close"
                @click="clickDrawerClose"
              >
                <i class="iconfont icon-close"></i>
              </div>
              <div class="content">
                <h5>{{ choseeAddressInfo.storeName }}</h5>
                <div
                  v-if="
                    getLocationFields({ info: choseeAddressInfo, type: 'logo' }) ||
                      getLocationFields({ info: choseeAddressInfo, type: 'label' })
                  "
                  class="logistics"
                >
                  <div
                    v-if="getLocationFields({ info: choseeAddressInfo, type: 'logo' })"
                    class="logo-panel"
                  >
                    <img
                      class="logo"
                      :src="getLocationFields({ info: choseeAddressInfo, type: 'logo' })"
                      alt="logo"
                    />
                  </div>
                  <span
                    v-if="getLocationFields({ info: choseeAddressInfo, type: 'label' })"
                    class="type"
                  >
                    {{
                      language[getLocationFields({ info: choseeAddressInfo, type: 'label' })]
                    }}
                  </span>
                </div>
                <p class="desc flex">
                  <i class="suiiconfont sui_icon_location_15px"></i>
                  <span class="info">{{ choseeAddressInfo.address }}</span>
                </p>
                <p
                  v-if="choseeAddressInfo.tel"
                  class="open-time tel flex"
                >
                  <i class="suiiconfont sui_icon_phone_15px_2"></i>
                  <span class="info">{{ choseeAddressInfo.tel }}</span>
                </p>
                <div
                  v-if="showTimesIcon(choseeAddressInfo)"
                  class="open-time flex"
                >
                  <i class="suiiconfont sui_icon_time_15px"></i>
                  <div
                    v-if="choseeAddressInfo.store247 === '1'"
                    class="info"
                  >
                    {{ language.SHEIN_KEY_PWA_20626 }}
                  </div>
                  <div
                    v-else-if="choseeAddressInfo.open_close_time"
                    class="info"
                  >
                    {{ choseeAddressInfo.open_close_time }}
                  </div>
                  <div
                    v-else
                    class="child info"
                  >
                    <time-vue
                      :time-info="choseeAddressInfo"
                      :language="language"
                    />
                  </div>
                </div>
              </div>
              <div class="bottom">
                <button
                  class="mshe-btn-black mshe-btn-block j-adr-submit-btn"
                  DA-type="syncClick"
                  @click="clickSelected"
                >
                  {{ language.SHEIN_KEY_PWA_15150 }}
                </button>
              </div>
            </div>
          </s-drawer>
        </SLazyMount>
        <SLazyMount>
          <s-drawer
            v-model:visible="isCascaderShow"
            class="choose-address-store-ru"
            append-to-body
          >
            <address-cascader
              :language="language"
              :type="storeConfig.type"
              :options="storeConfig.options"
              :page-name="pageName"
              :abt-test-config="abtTestConfig"
              :addressStoreConfig="addressStoreConfig"
              :sort-type="sortType"
              :show="isCascaderShow"
              :item-index="selectedIndex"
              @change="changeTabItem"
              @select="selectStore"
              @confirm="clickSelected"
              @cancel="isCascaderShow = false"
              @search="toSearch"
            />
          </s-drawer>
        </SLazyMount>
      </div>
    </div>
  </s-drawer>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
import {  saObj } from '../analysis'
import timeVue from './time'
import { asyncLoadFile, isNumber } from '@shein/common-function'
import addressCascader from '../child_components/address_cascader_store_ru.vue'
import {
  MAP_MARKER_ICONS,
  countryConfigs,
  RU_STORE_LOCATION_TYPE,
  RU_STORE_LAST_LOGISTICS
} from '../config'
import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { STitleNavItem } from '@shein-aidc/sui-title-nav-item/mobile'
import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
import { enterkey } from 'public/src/pages/common/ada/index.js'
import { monitorAddress } from 'public/src/pages/components/address/monitor/index.js'

const { icon1, icon2, icon3 } = MAP_MARKER_ICONS
// 店配级联选择字段
const storeTypes = ['state', 'city', 'store']
const {
  PUBLIC_CDN,
  SHOP_TRANSIT_GEOCODE_COUNTRIES,
  GOOGLE_API_KEY,
  GOOGLE_LAT_LNG_ACCURACY,
} = gbCommonInfo

export default defineComponent({
  name: 'MapRuVue',
  components: {
    timeVue,
    addressCascader,
    STitleNav,
    STitleNavItem,
    SDrawer,
    SLazyMount
  },
  directives: {
    enterkey,
  },
  emits: ['select'],
  props: {
    sourcePage: {
      type: String,
      default: ''
    },
    abtTestConfig: {
      type: Object,
      default() {
        return {
          ShowType: 'off',
          ShowLogo: 'off'
        }
      }
    },
    addressStoreConfig: {
      type: Object,
      default() {
        return {
          store_config: {
            distance: 0,
            sort_type: 0
          },
          abtTestConfig: {
            search_home: 'off',
            google: 'off',
            ShowLogo: 'off',
            distance: 'off',
            pic: 'off',
            ShowType: 'off',
            logicts: 'off',
            channel: [],
          },
          store_info_list: []
        }
      }
    }
  },
  data() {
    return {
      RU_STORE_LOCATION_TYPE,
      RU_STORE_LAST_LOGISTICS,
      language: {},
      storeId: '',
      pageName: '',
      countryId: '',
      show: false,
      PUBLIC_CDN,
      mapObj: null,
      selectedIndex: '',
      selectedMarker: '',
      googleMapShow: false,
      resultList: [],
      chooseAddressDrawer: false,
      choseeAddressInfo: {},
      markerList: [],
      myMarker: '',
      store_cache: {
        state: {},
        city: {}
      },
      stateList: [],
      cityList: [],
      storeConfig: {
        type: 'state',
        options: {
          state: {
            id: 'state',
            isShow: false,
            value: '',
            placeholder: 'SHEIN_KEY_PWA_15076',
            search: 'SHEIN_KEY_PWA_15076',
            subs: []
          },
          city: {
            id: 'city',
            isShow: false,
            value: '',
            placeholder: 'SHEIN_KEY_PWA_15075',
            search: 'SHEIN_KEY_PWA_15075',
            subs: []
          },
          store: {
            id: 'store',
            isShow: false,
            value: '',
            placeholder: 'SHEIN_KEY_PWA_17979',
            search: 'SHEIN_KEY_PWA_17979',
            subs: []
          }
        }
      },
      isCascaderShow: false,
      isLoading: false,
      sortType: '',
      extraData: {},
    }
  },
  computed: {
    isShowType() {
      return this.abtTestConfig.ShowType === 'on'
    },
    isShowLogo() {
      return this.abtTestConfig.ShowLogo === 'on'
    }
  },
  async created() {
    this.searchCache = {}
    this.getSortTypeAbt()
    await this.loadGoogleAPI()
    this.language = await this.fetchData()
  },
  methods: {
    showTimesIcon(item){
      return (item.timeLists && item.timeLists.length && item.timeLists.some(data => data.time !== '-')) || item.store247 === '1' || item.open_close_time
    },
    async getSortTypeAbt() {
      if (isNumber(this.sortType)) {
        return
      }
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: 'StoreSortMethod' })
      const sortType =
        abtInfo?.['StoreSortMethod']?.param?.['Store_Sort_Method'] === 'cost_sort' ? 1 : 0
      this.sortType = sortType
    },
    getLocationFields({ info = {}, type = 'logo' }) {
      if (type === 'logo') {
        const { last_logistics } = info
        if (!this.isShowLogo || !last_logistics) {
          return ''
        }
        const storeItemLogo =  this.addressStoreConfig?.store_info_list.find(item => item.store_channel_type == last_logistics)?.logo
        return storeItemLogo ? storeItemLogo :  RU_STORE_LAST_LOGISTICS[last_logistics] || ''
      }
      const { location_type } = info
      if (!this.isShowType || !location_type) {
        return ''
      }
      return RU_STORE_LOCATION_TYPE[location_type]?.value || ''
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    async fetchData() {
      const data = await schttp({
        url: '/api/user/addressbook/language/get',
        params: {
          type: 2
        }
      })
      return (data && data.language) || {}
    },
    loadGoogleAPI({ lat = '', lng = '' } = {}) {
      // eslint-disable-next-line no-async-promise-executor, no-unused-vars
      return new Promise(async (resolve, reject) => {
        if (!this.mapObj) {
          await asyncLoadFile({
            label: 'script',
            attrs: {
              src: `//maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`,
              async: 'async'
            }
          })
        }
        monitorAddress({
          metric_name: 'web_customer_address_click_associated_mapsearch_total',
          tags: {
            result: typeof google !== 'undefined' ? '1' : '0',
            country_id: String(this.countryId || '-'),
            address_type: 'shopTransit'
          }
        })
        try {
          if (Number(lat) && Number(lng)) {
            this.initMap({ lat, lng })
          } else {
            this.defaultMap()
          }
        } catch (e) {
          console.log(e)
        }
        resolve()
      })
    },
    async initConfigData({ countryId = '', pageName = '', storeId = '', extraData = {} } = {}) {
      this.countryId = +countryId
      this.pageName = pageName
      this.storeId = storeId
      this.selectedIndex = ''
      this.resultList = []
      this.chooseAddressDrawer = false
      this.choseeAddressInfo = {}
      this.extraData = extraData
      this.initStoreConfig()
      this.removeMarkers()
    },
    async toSearch({ keywords = '' }) {
      const state = this.storeConfig.options.state.value
      const city = this.storeConfig.options.city.value
      await this.getStoreLists({ postcode: '', state, city, keywords })
    },
    initStoreConfig() {
      storeTypes.forEach(type => {
        this.updateStoreConfigOptions({
          type,
          data: {
            value: '',
            isShow: false,
            subs: []
          }
        })
      })
    },
    showCascader() {
      if (this.isCascaderShow) {
        return
      }
      this.isCascaderShow = true
    },
    updateStoreConfigOptions({ type = '', data = {} }) {
      this.storeConfig.options = {
        ...this.storeConfig.options,
        [type]: {
          ...this.storeConfig.options[type],
          ...data
        }
      }
    },
    changeTabItem(type) {
      this.storeConfig.type = type
    },
    async selectStore({ type = '', value = '', item = {} }) {
      this.storeConfig.options[type].value = value
      this.selectedIndex = ''
      if (type === 'store') {
        const index = this.resultList.findIndex(listItem => listItem.storeId === item.storeId)
        this.selectedIndex = index
        this.setAddressInfo()
        return
      }
      const curTabIndex = storeTypes.findIndex(item => item === type)
      storeTypes.forEach((type, typeIndex) => {
        if (typeIndex > curTabIndex) {
          this.updateStoreConfigOptions({
            type,
            data: {
              value: '',
              subs: [],
              isShow: false
            }
          })
        }
      })
      if (type === 'state') {
        await this.bindStoreData('city')
        this.storeConfig.type = 'city'
        const city = this.storeConfig.options.city.subs[0]?.value
        await this.getStoreLists({ postcode: '', state: value, city })
      } else if (type === 'city') {
        await this.getStoreLists({
          postcode: '',
          state: this.storeConfig.options.state.value,
          city: value
        })
        this.storeConfig.type = 'store'
        this.updateStoreConfigOptions({
          type: 'store',
          data: {
            isShow: true
          }
        })
      }
      this.setMapData()
    },
    async bindStoreData(level) {
      let toType = level
      let cacheId = ''
      let param = {
        toType,
        countryId: '178'
      }
      let state = this.storeConfig.options.state.value
      // let city = this.storeConfig.options.city.value
      if (toType == 'state') {
        cacheId = 'state'
      } else if (toType == 'city') {
        // 不在店配地址库
        const stateList = this.storeConfig.options.state.subs
        if (stateList.length && !stateList.some(item => item.value === state)) {
          state = stateList[0]?.value
        }
        cacheId = `${toType}_${state}`
        param.state = encodeURIComponent(state)
      }
      if (!this.store_cache[level][cacheId]) {
        try {
          const json = await schttp({
            url: '/api/user/addressbook/storeAddressByParentName/get',
            params: param
          })
          monitorAddressApi({
            apiPath: '/api/user/addressbook/storeAddressByParentName/get',
            response: json,
            params: param
          })
          if (json.code == 0) {
            this.store_cache[level][cacheId] = json && json.info && json.info.res //缓存city
            this.updateList(level, cacheId)
          }
        } catch (error) {
          return
        }
      } else {
        this.updateList(level, cacheId)
      }
    },
    updateList(level, cacheId) {
      const array = this.store_cache[level][cacheId]
      const subs = array ? array.map(item => ({ value: item[level] })) : []
      this.updateStoreConfigOptions({
        type: level,
        data: {
          subs,
          isShow: true
        }
      })
    },
    async setMapData() {
      try {
        if (this.resultList.length) {
          this.initMap()
        } else {
          this.defaultMap()
        }
        await this.sleep(600)
        this.setAddressInfo()
      } catch (e) {
        console.log(e)
      }
    },
    setAddressInfo() {
      if (this.resultList.length && this.selectedIndex !== '') {
        this.chooseAddressDrawer = true
        this.updateStoreConfigOptions({
          type: 'store',
          data: {
            value: this.resultList[0].storeName,
            isShow: true
          }
        })
        this.choseeAddressInfo = this.resultList[this.selectedIndex]
        this.selectedDefaultMarker(this.markerList[this.selectedIndex])
      }
    },
    async initData({
      show = true,
      state,
      city,
      storeId = '',
      pageName = '',
      countryId = '',
      lat = '',
      lng = '',
      extraData = {}
    } = {}) {
      this.show = show
      await this.initConfigData({ countryId, pageName, storeId, extraData })
      await this.bindStoreData('state')
      this.updateStoreConfigOptions({
        type: 'state',
        data: {
          value: state
        }
      })
      this.storeConfig.type = 'state'
      if (lat && lng) {
        await this.getMrInfo({ lat, lng })
      } else if (state && city) {
        await this.bindStoreData('city')
        this.updateStoreConfigOptions({
          type: 'city',
          data: {
            value: city
          }
        })
        this.storeConfig.type = 'city'
        await this.getStoreLists({ postcode: '', state, city })
        this.updateStoreConfigOptions({
          type: 'store',
          data: {
            isShow: true
          }
        })
        if (this.resultList.length) {
          const index = this.resultList.findIndex(item => item.storeId === this.storeId)
          this.selectedIndex = index
          this.storeConfig.type = 'store'
        } else {
          this.storeConfig.type = 'state'
          storeTypes.forEach(type => {
            this.updateStoreConfigOptions({
              type,
              data: {
                value: '',
                isShow: type === 'state'
              }
            })
          })
        }
      }
      await this.loadGoogleAPI()
      this.setMapData()
      this.showCascader()
    },
    defaultMap(center = { lat: 55.916666666666664, lng: 37.61666666666667 }, zoom = 6) {
      if (this.mapObj) {
        this.removeMarkers()
        this.mapObj.setCenter(center)
        this.mapObj.setZoom(zoom)
      } else {
        // eslint-disable-next-line no-undef
        this.mapObj = new google.maps.Map(document.getElementById('j-store-map-ru'), {
          zoom,
          center,
          clickableIcons: false,
          gestureHandling: 'greedy'
        })
      }
    },
    initMap(data = {}) {
      const { lat = '', lng = '', zoom = 13 } = data
      const defaultIndex = this.selectedIndex || 0
      const center = {
        lat: parseFloat(lat || this.resultList[defaultIndex].Latitude),
        lng: parseFloat(lng || this.resultList[defaultIndex].Longitude)
      }
      this.defaultMap(center, zoom)
      this.removeMarkers()
      if (lat && lng) {
        // eslint-disable-next-line no-undef
        this.myMarker = new google.maps.Marker({
          position: center,
          icon: icon3,
          map: this.mapObj
        })
      }
      this.resultList.forEach((item, index) => {
        this.addMarker(item, index)
      })
      this.mapObj.addListener('dragstart', () => {
        this.chooseAddressDrawer = false
      })
    },
    addMarker(item, index) {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        resultIndex: index,
        resultItem: item,
        position: {
          lat: parseFloat(item.Latitude),
          lng: parseFloat(item.Longitude)
        },
        icon: this.selectedIndex === index ? icon2 : icon1,
        map: this.mapObj
      })
      if (item.distance) {
        // eslint-disable-next-line no-undef
        const infowindow = new google.maps.InfoWindow({
          content: `<div class="${['popup-info', item.isRecommend ? 'recommend' : '']
            .filter(Boolean)
            .join(' ')}">
            <div class="distance">
              <i class="suiiconfont sui_icon_shop_16px_2"></i>
              <span class="num">${this.transformDistance(item)}</span>
            </div>
            <div class="triangle"></div>
          </div>`
        })
        infowindow.open({
          anchor: marker,
          map: this.mapObj,
          shouldFocus: false
        })
      }
      if (this.selectedIndex === index) {
        this.selectedMarker = marker
      }
      marker.addListener('click', () => {
        this.selectedDefaultMarker(marker)
      })
      this.markerList.push(marker)
      return marker
    },
    // 选中店铺
    selectedDefaultMarker(marker) {
      this.selectedIndex = marker.resultIndex
      this.updateStoreConfigOptions({
        type: 'store',
        data: {
          value: marker?.resultItem?.storeName
        }
      })
      this.chooseAddressDrawer = true
      this.choseeAddressInfo = marker.resultItem
      if (this.selectedMarker) {
        this.selectedMarker.setIcon(icon1)
      }
      marker.setIcon(icon2)
      this.mapObj.setCenter({
        lat: parseFloat(marker.resultItem.Latitude),
        lng: parseFloat(marker.resultItem.Longitude)
      })
      this.mapObj.setZoom(16)
      this.selectedMarker = marker
    },
    // 移除标记
    removeMarkers() {
      this.markerList.forEach(item => {
        item.setMap(null)
      })
      this.markerList = []
      this.myMarker && this.myMarker.setMap(null)
    },
    // 离我最近
    async nearestForMe({
      show = true,
      lat = '',
      lng = '',
      storeId = '',
      pageName = '',
      countryId = '',
      extraData = {}
    } = {}) {
      this.show = show
      await this.initConfigData({ countryId, pageName, storeId, extraData })
      if (lat && lng) {
        await this.getMrInfo({ lat, lng })
      }
      if (this.resultList.length) {
        const { state, city } = this.resultList[0] || {}
        await this.bindStoreData('state')
        this.updateStoreConfigOptions({
          type: 'state',
          data: {
            value: state
          }
        })
        await this.bindStoreData('city')
        this.updateStoreConfigOptions({
          type: 'city',
          data: {
            value: city
          }
        })
        this.updateStoreConfigOptions({
          type: 'store',
          data: {
            isShow: true
          }
        })
        this.storeConfig.type = 'store'
        await this.loadGoogleAPI({ lat, lng })
      } else {
        this.initData({ storeId, pageName, countryId })
      }
    },
    resolveDistanceConfig(distance) {
      if (this.abtTestConfig.distance !== 'on' || distance === '') {
        return {
          distance: '',
          isRecommend: false
        }
      }
      if (this.sortType !== 1) {
        return {
          distance,
          isRecommend: false
        }
      }
      const curDistance = Number(distance || 0)
      const maxDistance = Number(this.addressStoreConfig?.store_config?.distance || 0)
      const isRecommend = curDistance < maxDistance
      return {
        distance: String(isRecommend ? maxDistance : curDistance),
        isRecommend
      }
    },
    // 设置字段数据
    setAddressLists(addressList) {
      const {
        SHEIN_KEY_PWA_16638,
        SHEIN_KEY_PWA_16639,
        SHEIN_KEY_PWA_16640,
        SHEIN_KEY_PWA_16641,
        SHEIN_KEY_PWA_16642,
        SHEIN_KEY_PWA_16643,
        SHEIN_KEY_PWA_16644
      } = this.language
      const lists = addressList.map(item => {
        const { distance, isRecommend } = this.resolveDistanceConfig(
          item.Distance || item.distance || ''
        )
        return {
          storeName: item.store_name,
          address: ['address1', 'address2', 'state', 'city', 'street', 'district']
            .map(type => item[type])
            .filter(Boolean)
            .join(' '),
          postcode: item.postcode,
          storeId: item.store_id,
          city: item.city,
          Latitude: item.lat,
          Longitude: item.lng,
          state: item.state || '',
          tel: item.tel || '',
          last_logistics: item.last_logistics,
          location_type: item.location_type,
          distance,
          isRecommend,
          open_close_time: item.open_close_time || '',
          timeLists: [
            {
              date: SHEIN_KEY_PWA_16638,
              time: `${[item.mon_open_time, item.mon_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16639,
              time: `${[item.tue_open_time, item.tue_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16640,
              time: `${[item.wed_open_time, item.wed_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16641,
              time: `${[item.thu_open_time, item.thu_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16642,
              time: `${[item.fri_open_time, item.fri_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16643,
              time: `${[item.sat_open_time, item.sat_close_time].filter(Boolean).join('-')}`
            },
            {
              date: SHEIN_KEY_PWA_16644,
              time: `${[item.sun_open_time, item.sun_close_time].filter(Boolean).join('-')}`
            }
          ].filter(item => !!item.time)
        }
      })
      return lists
    },
    async getStoreLists({ postcode, keywords, state, city }) {
      const { lat = '', lng = '' } = await this.getLatLng({ postcode, state, city })
      await this.getMrInfo({ postcode, keywords, state, city, lat, lng })
    },
    async getLatLng({ postcode, state, city }) {
      const form = {
        postal_code: postcode,
        administrative_area: state,
        locality: city,
        country: countryConfigs?.[this.countryId]?.country
      }
      if (!SHOP_TRANSIT_GEOCODE_COUNTRIES?.includes(+this.countryId) || (!state && !city)) {
        return {}
      }
      const data = await schttp({
        url: '/api/user/addressbook/geo/get',
        params: form
      })
      monitorAddressApi({
        apiPath: '/api/user/addressbook/geo/get',
        response: data,
        params: form
      })
      const { lat = '', lng = '' } = data?.results?.[0]?.geometry?.location || {}
      return { lat, lng }
    },
    // 过滤退货选择俄罗斯店配type8，11以外的店配
    ruCdekFilterType(list, countryid) {
      if (countryid == 178 && list.length && this.sourcePage == 'orderReturn') {
        return list.filter(item => {
          if ([8, 11].includes(Number(item.type))) {
            return item
          }
        })
      } else {
        return list
      }
    },
    // 查询店铺
    async getMrInfo({
      postcode = '',
      lat = '',
      lng = '',
      state = '',
      city = '',
      keywords = ''
    } = {}) {
      if (this.isLoading) {
        return
      }
      const cacheKey = `COUNTRY_${this.countryId}_STATE_${state}_CITY_${city}_LAT_${lat}_LNG=${lng}_KEY=${keywords}`
      if (this.searchCache[cacheKey]) {
        this.selectedIndex = ''
        this.chooseAddressDrawer = false
        this.resultList = this.searchCache[cacheKey]
        this.updateStoreConfigOptions({
          type: 'store',
          data: {
            value: '',
            subs: this.resultList
          }
        })
        saObj.exposeStoreSuggestion({
          store_sort: this.resultList.some(
            item => Number(item.distance) < Number(this.resultList?.[0]?.distance)
          )
            ? 0
            : 1
        })
        return
      }
      const accuracy = GOOGLE_LAT_LNG_ACCURACY?.[this.countryId] || 4
      const { pageSource, billno = '' } = this.extraData
      const pageSources = {
        order_return: 0,
        checkout: 1
      }
      const form = {
        countryId: this.countryId,
        postcode,
        latitude: lat ? Number(lat)?.toFixed(accuracy) : '',
        longitude: lng ? Number(lng)?.toFixed(accuracy) : '',
        state,
        city,
        keywords,
        sortType: this.sortType,
        pageSource: Object.keys(pageSources).includes(pageSource) ? pageSources?.[pageSource] : 2,
        billno,
      }
      this.isLoading = true
      const data = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/storeAddressList/get',
        data: form
      })
      monitorAddressApi({
        apiPath: '/api/user/addressbook/storeAddressList/get',
        response: data,
        params: form
      })
      this.isLoading = false
      let addressList = data.code == 0 && data.info && (data.info.addressList || data.info.result)
      addressList = this.ruCdekFilterType(addressList, form.countryId)
      if (addressList.length) {
        this.resultList = this.setAddressLists(addressList)
        saObj.exposeStoreSuggestion({
          store_sort: this.resultList.some(
            item => Number(item.distance) < Number(this.resultList?.[0]?.distance)
          )
            ? 0
            : 1
        })
        this.searchCache[cacheKey] = this.resultList
      } else {
        this.resultList = []
      }
      this.selectedIndex = ''
      this.chooseAddressDrawer = false
      this.choseeAddressInfo = {}
      this.updateStoreConfigOptions({
        type: 'store',
        data: {
          value: '',
          subs: this.resultList
        }
      })
    },
    clickCancel() {
      this.chooseAddressDrawer = false
      this.isCascaderShow = false
      this.show = false
    },
    getSortPosition({ index } = {}) {
      if (this.sortType === 0) {
        return 0
      }
      const { isRecommend } = this.resultList[index] || {}
      const position = isRecommend ? (index == 0 ? 1 : 2) : 3
      return position
    },
    clickSelected() {
      this.chooseAddressDrawer = false
      this.isCascaderShow = false
      nextTick(() => {
        this.clickCancel()
        saObj.clickSelectpickupaddressMapConfirm({
          sort: this.sortType,
          store_select: this.getSortPosition({ index: this.selectedIndex })
        })
        this.$emit('select', this.resultList[this.selectedIndex])
      })
    },
    clickDrawerClose() {
      this.chooseAddressDrawer = false
    },
    transformDistance(item = {}) {
      const { distance, isRecommend } = item
      if (!distance) {
        return ''
      }
      if (distance.length > 3) {
        const [int, dec] = (distance / 1000).toFixed(2).split('.')
        return `${isRecommend ? '< ' : ''}${Number(int).toLocaleString()}.${dec}km`
      }
      return `${isRecommend ? '< ' : ''}${distance}m`
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.choose-address-store-ru {
  .c-aside {
    top: 4rem;
    z-index: 201; /* stylelint-disable-line declaration-property-value-blacklist */
    width: 100%;
  }
  .mshe-mask {
    z-index: 200; /* stylelint-disable-line declaration-property-value-blacklist */
  }
}
.j-map-address {
  .c-address-content {
    &.page-empty {
      background: #fff;
    }
    .address {
      .header-search {
        position: relative;
        .flexbox;
        .align-center;
        padding: 0.16rem 0.32rem;
        background: #fff;
        .search-input {
          position: relative;
          padding: 0;
          flex: 1;
          > input {
            width: 100%;
            background: #f6f6f6;
            height: 0.85333rem;
            line-height: 0.85333rem;
            .font-dpr(28px);
            border: none;
            padding: 0 0.90666rem;
          }
          .icon-search01 {
            position: absolute;
            .left(0.21333rem);
            top: 0.1rem;
            .font-dpr(36px);
            color: #999;
          }
          .icon-clear1 {
            position: absolute;
            .right(0.21333rem);
            top: 0.18rem;
            color: #ccc;
            .font-dpr(28px);
          }
          .text-placeholer {
            position: absolute;
            top: 0.2rem;
            left: 0.9rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 6rem;
            color: #ccc;
          }
        }
        .search-right {
          .font-dpr(28px);
          color: #222;
          .padding-l(0.32rem);
        }
      }
      .search-error {
        padding: 0.053333rem 0.32rem 0.32rem;
        .font-dpr(28px);
        color: #ff411c;
        background: #fff;
      }
      .select-map {
        padding: 0.21333rem 0;
        text-align: center;
        background: #fff;
        color: #093c6e;
        .border-dpr(border-top, 2px, #e5e5e5);
        .border-dpr(border-bottom, 2px, #e5e5e5);
        .suiiconfont {
          .font-dpr(30px);
        }
        .value {
          .font-dpr(28px);
          padding: 0 0.05rem;
        }
      }
      .search-result {
        background: #fff;
        .result-list {
          .margin-l(0.32rem);
          margin-bottom: 1.5rem;
          .result-item {
            .flexbox;
            .border-dpr(border-bottom, 2px, #e5e5e5);
            padding: 0.32rem;
            .padding-l(0);
            .result-content {
              flex: 1;
              .padding-l(0.26666rem);
              color: #666;
              .font-dpr(28px);
              .title {
                .font-dpr(32px);
                color: #222;
                width: 8.5rem;
                margin-bottom: 0.08rem;
                .distance {
                  .margin-l(0.267rem);
                  .font-dpr(24px);
                  white-space: nowrap;
                }
              }
              .desc {
                .font-dpr(28px);
                color: #666;
                margin-bottom: 0.21333rem;
              }
              .child {
                .padding-l(0.57rem);
                .time-list {
                  > li {
                    .flexbox;
                    padding: 0.08rem 0;
                    .date,
                    .time {
                      flex: 1;
                    }
                  }
                }
              }
              .flex {
                display: flex;
                align-items: center;
                .suiiconfont {
                  align-self: flex-start;
                  color: #222;
                  color: #222;
                  .font-dpr(30px);
                }
                .info {
                  flex: 1;
                  color: @sui_color_gray_dark2;
                  .font-dpr(28px);
                  .margin-l(0.16rem);
                  &.inline {
                    display: inline-flex;
                    flex-direction: column;
                    .link {
                      color: @sui_color_link;
                      .font-dpr(28px);
                      text-decoration: none;
                    }
                  }
                }
                &.title {
                  justify-content: space-between;
                }
              }
              .post-number {
                padding: 0.213rem 0.32rem;
                margin-top: 0.267rem;
                background: @sui_color_gray_weak2;
                .info {
                  margin: 0;
                  .margin-r(0.16rem);
                  color: @sui_color_gray_dark3;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .map {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 2.4rem;
      &.error {
        top: 3.3rem;
      }
      .gm-style-mtc,
      .gm-svpc,
      .gm-fullscreen-control {
        display: none;
      }
    }
    .page-empty {
      text-align: center;
      .font-dpr(28px);
      color: #999;
      > img {
        width: 2rem;
        margin-top: 2rem;
      }
      > p {
        padding: 0.64rem;
      }
    }
    &.c-map-wrap.ru {
      .search-btn {
        position: fixed;
        top: 1.84rem;
        .right(0.64rem);
        z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.33rem;
        height: 1.33rem;
        background: #fff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
        border-radius: 0.106rem;
        .sui_icon_nav_search_18px {
          color: #222;
          .font-dpr(56px);
        }
      }
      .map {
        top: 1.17rem;
      }
      .choose-address-page .choose-address-wrap .content {
        max-height: 6.5rem;
      }
    }
  }
  .result-bottom {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    padding: 0.21333rem 0.32rem;
  }
}

.j-address-box {
  .c-address-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 200; /* stylelint-disable-line declaration-property-value-blacklist */
  }
}
.c-google-map-modal {
  .gm-style-iw.gm-style-iw-c {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    border-radius: 0;
    background: transparent !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    .popup-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      margin-bottom: -5px;
      .distance {
        padding: 12px;
        background: #fff;
        box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.3);
      }
      .triangle {
        border-top: 12px solid #fff;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
      }
      &.recommend {
        .distance {
          background: @sui_color_safety_bg;
          color: @sui_color_safety;
          font-weight: bold;
        }
        .triangle {
          border-top: 12px solid @sui_color_safety_bg;
        }
      }
    }
    button.gm-ui-hover-effect {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
    .gm-style-iw-d {
      overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .gm-style-iw-t {
    .gm-style-iw-tc {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

/* shipping抽屉 */
.mche-ship-drawer-aside {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: #fff;
  height: 8.19rem;
  .shipping-drawer-header {
    height: 1.07rem;
    background: #fff;
    width: 100%;
    a {
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      text-decoration: none;
      .icon-close {
        font-size: 16px;
      }
    }
    .drawer-header-title {
      text-align: center;
      color: #999999;
      position: absolute;
      left: 0;
      right: 0;
      font-weight: 500;
      .font-dpr(32px);
    }
  }
  .shipping-drawer-scroll-box {
    padding-bottom: 1.96rem + 0.32rem + 0.21rem;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>
